@use "../vars" as *;

div#app > footer {
  background: $color-bg-darker;
  padding-top: 32px;

  > div {
    display: flex;
    flex-flow: row wrap;
    margin: auto;
    padding: $layout-content-padding;
    max-width: $layout-content-width;

    > span.copy {

      > strong {
        margin-left: 2px;
      }
    }

    > nav {
      display: flex;
      margin-left: auto;

      > a:not(:first-child)::before {
        content: "|";
        margin: 0 7px;
        opacity: 0.5;
      }
    }
  }

  @media (max-width: 640px) {
    padding-top: 28px;

    > div {
      flex-flow: column;
      align-items: center;

      > nav {
        margin: 20px 0 0 0;
      }
    }
  }
}