@use "../../vars" as *;
@use "../../funcs" as *;

section#playlist {
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 20px;
  text-align: left;

  @media (max-width: 900px) {
    display: block;
  }

  > header {
    position: sticky;
    top: 15px;

    > strong {
      @include mask("icon-playlist", $color-fg-light, 32px);
      display: block;
      padding-left: 32px;
      font-size: 120%;
      line-height: 140%;

      &::before {
        top: -1px;
        left: -5px;
        width: 32px;
        height: 32px;
      }
    }

    > img {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 7px 0 15px;
    }

    div.prefix {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      max-width: 125px;
      margin: 20px auto 0;

      > div {

        > label {
          font-size: 90%;
        }

        > input {
          @extend %formField;
          font-size: 80%;
          padding: 2px 4px;
          margin: 2px 0 0 0;
        }
      }
    }

    @media (max-width: 900px) {
      position: relative;
      top: unset;
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;
      margin-bottom: 50px;

      > strong {
        grid-column: 1 / span 2;
      }

      > img {
        margin: 0;
      }

      > div {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;

        > button.back {
          display: none;
        }

        > button.download {
          margin: 0 20px 0 0;
        }

        > div.prefix {
          margin: 0;
        }
      }
    }

    @media (max-width: 500px) {
      display: block;

      > img {
        margin: 10px 0 22px;
      }
    }
  }

  > form {
    grid-column: 2;
    grid-row: 1 / span 2;
    margin-top: 5px;

    > div.loading {
      transform: scale(0.5);
      margin-top: 30px;
    }

    > ul {

      > li {
        display: grid;
        grid-template-columns: 22px 1fr;
        grid-column-gap: 5px;

        + li {
          margin-top: 40px;

          &::before {
            content: "";
            position: absolute;
            top: -19px;
            left: 0;
            width: 100%;
            height: 1px;
            background: rgba(0, 0, 0, 0.2);
          }
        }

        > input[type='checkbox'] {
          @extend %checkbox;
          margin-top: 2px;
        }

        > div {
          display: grid;
          grid-template-columns: 120px 1fr;
          grid-column-gap: 10px;
          grid-row-gap: 5px;

          > strong {
            grid-column: 1 / span 2;
            font-size: 100%;
          }

          > div.cover {

            > span {
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              width: 120px;
              height: 120px;
              user-select: none;
              transition: transform 0.3s, box-shadow 0.3s;

              &:hover {
                z-index: 3;
              }

              &:active {
                transform: scale(5);
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
              }

              > img {
                height: 100%;
                pointer-events: none;

                &[src=''] {
                  width: 100%;
                  font-size: 0;

                  &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: $color-bg-darker;
                  }
                }
              }
            }

            > button {
              @include mask("icon-edit", $color-fg-light, 12px);
              cursor: pointer;
              margin-top: 3px;
              padding-left: 16px;
              font-size: 80%;
              border: none;
              outline: none;
              background: none;
              transition: opacity 0.3s;

              &::before {
                width: 12px;
                height: 12px;
                top: 1px;
              }
            }

            > div.loading {
              position: absolute;
              transform: scale(0.4) translateX(-100px);
              z-index: 2;

              + span {
                pointer-events: none;
              }
            }
          }

          > div.details {

            > input {
              @extend %formField;
              display: block;
              font-size: 90%;
              padding: 6px 6px;
              max-width: 300px;

              + input {
                margin-top: 12px;
              }
            }
          }

          @media (max-width: 500px) {
            display: block;

            > div.cover {
              margin: 5px 0 10px;
            }
          }
        }
      }
    }
  }
}
