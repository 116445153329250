@use "vars" as *;

%formField {
  border: 2px solid $color-fg-light;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

%button {
  display: block;
  padding: 10px 20px;
  font-size: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px auto 0;
  background: $color-fg-main;
  color: $color-fg-white;
  transition: background-color 0.5s;

  &.disabled {
    background: $color-fg-light;
    pointer-events: none;
  }

  @media (max-width: 900px) {
    margin: 20px 0 0 0;
  }
}

%checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 2px solid $color-fg-light;
    border-radius: 3px;
    transition: border 0.2s, height 0.2s, transform 0.2s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-bg-body;
    cursor: pointer;
  }

  &:checked::before {
    transform: rotateZ(-45deg);
    border-color: $color-fg-green;
    border-top-style: none;
    border-right-style: none;
    border-radius: 0;
    height: 50%;
  }
}


@function img($path) {
  @return url(../img/#{$path});
}

@mixin maskImage($icon) {
  &::before {
    -webkit-mask-image: img("#{$icon}.svg");
  }
}

@mixin maskSize($size) {
  &::before {
    -webkit-mask-size: $size;
    mask-size: $size;
  }
}

@mixin mask($icon,$color,$size) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-mask: no-repeat center / $size;
    @if ($icon) {
      -webkit-mask-image: img("#{$icon}.svg");
    }
    background-color: $color;
  }
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@mixin loading($__color:$color-fg-main, $__size:32px) {
  border-radius: 50%;
  border: #{$__size/10} solid transparent;
  border-top-color: $__color;
  border-bottom-color: $__color;
  width: $__size;
  height: $__size;
  animation: spin 2s linear infinite;
}