@charset "UTF-8";
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
body, input, textarea, select, button {
  font-size: 16px;
  font-family: "Work Sans", sans-serif;
  color: #666;
  font-weight: 300;
}

*, *::before, *::after {
  position: relative;
  margin: 0;
  padding: 0;
  outline: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  background: #ffffff;
  overflow-x: hidden;
}

ul, menu {
  list-style: none;
}
ul.bulletList > li, menu.bulletList > li {
  padding-left: 14px;
}
ul.bulletList > li + li, menu.bulletList > li + li {
  margin-top: 3px;
}
ul.bulletList > li::before, menu.bulletList > li::before {
  content: "–";
  margin: 0 6px 0 -16px;
  display: inline-block;
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer;
}

a.back, button.back {
  cursor: pointer;
  padding-left: 15px;
  border: none;
  outline: none;
  background: none;
}
a.back::before, button.back::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 7px;
  height: 7px;
  border-top: 2px solid #aaa;
  border-left: 2px solid #aaa;
  transform: rotateZ(-45deg);
}
@media (max-width: 900px) {
  a.back, button.back {
    margin: 20px 0 0 0;
  }
}

strong {
  font-weight: 400;
}

picture {
  width: 100%;
}

picture > img {
  width: 100%;
}

div#app {
  display: grid;
  grid-template-rows: 200px 1fr 80px;
  min-height: 100vh;
}
@media (max-width: 900px) {
  div#app {
    grid-template-rows: 150px 1fr 80px;
  }
}
@media (max-width: 640px) {
  div#app {
    grid-template-rows: 150px 1fr 120px;
  }
}
div#app main {
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  padding-top: 50px;
  margin: 0 auto 60px;
  text-align: center;
}
@media (max-width: 900px) {
  div#app main {
    padding-top: 35px;
    margin-bottom: 50px;
  }
}

section#details > div.videoDetails > form > div > input, section#playlist > header div.prefix > div > input, section#playlist > form > ul > li > div > div.details > input {
  border: 2px solid #aaa;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

button.download, section#finished > button {
  display: block;
  padding: 10px 20px;
  font-size: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px auto 0;
  background: #f6ba2a;
  color: #fff;
  transition: background-color 0.5s;
}
button.disabled.download, section#finished > button.disabled {
  background: #aaa;
  pointer-events: none;
}
@media (max-width: 900px) {
  button.download, section#finished > button {
    margin: 20px 0 0 0;
  }
}

section#playlist > form > ul > li > input[type=checkbox] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
section#playlist > form > ul > li > input[type=checkbox]::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  border-radius: 3px;
  transition: border 0.2s, height 0.2s, transform 0.2s;
}
section#playlist > form > ul > li > input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  cursor: pointer;
}
section#playlist > form > ul > li > input[type=checkbox]:checked::before {
  transform: rotateZ(-45deg);
  border-color: #7cce41;
  border-top-style: none;
  border-right-style: none;
  border-radius: 0;
  height: 50%;
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
div#app > header {
  background: #444444;
}
div#app > header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-left: 100vw solid #ffffff;
  z-index: 1;
}
@media (max-width: 900px) {
  div#app > header::after {
    border-top-width: 50px;
  }
}
div#app > header > div {
  margin: auto;
  height: 100%;
  padding: 0 20px;
  max-width: 800px;
  z-index: 2;
}
div#app > header > div > a#logo {
  display: block;
  width: 120px;
  height: 100%;
  font-size: 0;
  color: transparent;
  background: #f6ba2a;
  margin-top: -30px;
  border-radius: 0 0 15px 15px;
  transform: rotate(10deg);
}
div#app > header > div > a#logo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/50px;
  -webkit-mask-image: url(../img/logo.svg);
  background-color: #fff;
}
div#app > header > div > a#logo::before {
  transform: translateX(-4px) rotate(-10deg);
  pointer-events: none;
}
@media (max-width: 900px) {
  div#app > header > div > a#logo {
    margin: 0 20px;
    margin-top: -12px;
    width: 100px;
  }
  div#app > header > div > a#logo::before {
    -webkit-mask-size: 40px;
    mask-size: 40px;
  }
}

div#app > footer {
  background: #eeeeee;
  padding-top: 32px;
}
div#app > footer > div {
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  padding: 0 20px;
  max-width: 800px;
}
div#app > footer > div > span.copy > strong {
  margin-left: 2px;
}
div#app > footer > div > nav {
  display: flex;
  margin-left: auto;
}
div#app > footer > div > nav > a:not(:first-child)::before {
  content: "|";
  margin: 0 7px;
  opacity: 0.5;
}
@media (max-width: 640px) {
  div#app > footer {
    padding-top: 28px;
  }
  div#app > footer > div {
    flex-flow: column;
    align-items: center;
  }
  div#app > footer > div > nav {
    margin: 20px 0 0 0;
  }
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
body::before {
  content: "";
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
body.modal-visible::before {
  opacity: 1;
  pointer-events: auto;
}

section#modal {
  position: fixed;
  z-index: 999;
  width: calc(100% - 30px);
  top: 50px;
  left: 15px;
  right: 15px;
  text-align: left;
  margin: auto;
  background: #ffffff;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.3);
  padding: 30px 20px 20px;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, -20px, 0);
  transition: opacity 0.3s, transform 0.3s;
}
section#modal.visible {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
}
section#modal > button.dismiss {
  border: none;
  outline: none;
  background: none;
  color: #f6ba2a;
  margin-top: 20px;
  padding: 2px 7px;
  font-size: 110%;
  cursor: pointer;
  font-weight: 400;
  transition: background-color 0.3s;
}
section#modal > button.dismiss:hover {
  background: rgba(246, 186, 42, 0.1);
}
@media (max-width: 500px) {
  section#modal > button.dismiss {
    font-size: 100%;
  }
}

section#modal[data-name=info] {
  max-width: 400px;
  text-align: center;
}
section#modal[data-name=info] > p {
  font-size: 140%;
  line-height: 140%;
}
@media (max-width: 500px) {
  section#modal[data-name=info] > p {
    font-size: 120%;
    line-height: 130%;
  }
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
section#modal[data-name=coverArt] > div.manual > div > input#url {
  border: 2px solid #aaa;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
section#modal[data-name=coverArt] {
  max-width: 800px;
}
section#modal[data-name=coverArt] > header {
  height: 85px;
  padding-left: 115px;
}
section#modal[data-name=coverArt] > header > span.cover {
  position: absolute;
  top: -55px;
  left: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 130px;
  height: 130px;
  background: #eeeeee;
  user-select: none;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}
section#modal[data-name=coverArt] > header > span.cover:hover {
  z-index: 3;
}
section#modal[data-name=coverArt] > header > span.cover:active {
  transform: scale(3) translate(42px, 42px);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
}
section#modal[data-name=coverArt] > header > span.cover > img {
  height: 100%;
  pointer-events: none;
}
section#modal[data-name=coverArt] > header > span.cover > img[src=""] {
  width: 100%;
  font-size: 0;
}
section#modal[data-name=coverArt] > header > span.cover > img[src=""]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eeeeee;
}
section#modal[data-name=coverArt] > header > strong {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 120%;
}
section#modal[data-name=coverArt] > header > menu {
  display: flex;
  margin-top: 15px;
}
section#modal[data-name=coverArt] > header > menu > li {
  padding: 5px 12px;
  color: #aaa;
  cursor: pointer;
}
section#modal[data-name=coverArt] > header > menu > li.active {
  color: #fff;
  background: #f6ba2a;
}
section#modal[data-name=coverArt] > header > menu > li.search {
  margin-left: auto;
  padding: 0 3px 0 32px;
  width: 200px;
  border: 2px solid #aaa;
}
section#modal[data-name=coverArt] > header > menu > li.search::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/16px;
  -webkit-mask-image: url(../img/icon-search.svg);
  background-color: #aaa;
}
section#modal[data-name=coverArt] > header > menu > li.search::before {
  width: 32px;
}
section#modal[data-name=coverArt] > header > menu > li.search > input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 3px 0;
}
section#modal[data-name=coverArt] > ul.suggestions {
  overflow-x: auto;
  max-height: calc(100vh - 280px);
  margin-right: -20px;
}
section#modal[data-name=coverArt] > ul.suggestions > li {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 20px;
  padding: 12px 10px;
  transition: background-color 0.3s;
}
section#modal[data-name=coverArt] > ul.suggestions > li:hover {
  background: rgba(0, 0, 0, 0.05);
}
section#modal[data-name=coverArt] > ul.suggestions > li:hover > span.cover {
  z-index: 3;
}
section#modal[data-name=coverArt] > ul.suggestions > li + li::before {
  background: rgba(0, 0, 0, 0.2);
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
section#modal[data-name=coverArt] > ul.suggestions > li > span.cover {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100px;
  height: 100px;
  user-select: none;
  top: 0;
  left: 0;
  transition: transform 0.3s, top 0.3s, left 0.3s, box-shadow 0.3s;
}
section#modal[data-name=coverArt] > ul.suggestions > li > span.cover:active {
  transform: scale(3.5);
  left: 125px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
}
section#modal[data-name=coverArt] > ul.suggestions > li > span.cover > img {
  height: 100%;
  pointer-events: none;
}
section#modal[data-name=coverArt] > ul.suggestions > li:first-child > span.cover:active {
  top: 125px;
}
section#modal[data-name=coverArt] > ul.suggestions > li:last-child > span.cover:active {
  top: -125px;
}
section#modal[data-name=coverArt] > ul.suggestions > li[data-source=thumbnail] > div.info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/22px;
  -webkit-mask-image: url(../img/icon-youtube.svg);
  background-color: #aaa;
}
section#modal[data-name=coverArt] > ul.suggestions > li[data-source=spotify] > div.info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/22px;
  -webkit-mask-image: url(../img/icon-spotify.svg);
  background-color: #aaa;
}
section#modal[data-name=coverArt] > ul.suggestions > li[data-source=soundcloud] > div.info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/22px;
  -webkit-mask-image: url(../img/icon-soundcloud.svg);
  background-color: #aaa;
}
section#modal[data-name=coverArt] > ul.suggestions > li > div.info {
  padding-top: 10px;
}
section#modal[data-name=coverArt] > ul.suggestions > li > div.info::before {
  bottom: 7px;
  top: unset !important;
  width: 24px !important;
  height: 24px !important;
}
section#modal[data-name=coverArt] > ul.suggestions > li > div.info > span.artist {
  display: block;
  font-size: 90%;
  margin-bottom: 5px;
  color: #aaa;
}
section#modal[data-name=coverArt] > ul.suggestions > li > div.info > span.track {
  display: block;
}
section#modal[data-name=coverArt] > div {
  min-height: 200px;
}
section#modal[data-name=coverArt] > div.loading {
  transform: scale(0.5);
  margin: 0;
}
section#modal[data-name=coverArt] > div.suggestion-empty {
  padding: 30px 10px;
  font-style: italic;
  font-size: 110%;
}
section#modal[data-name=coverArt] > div.manual {
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-gap: 20px;
  padding: 30px 10px;
}
section#modal[data-name=coverArt] > div.manual > div > label {
  font-weight: 400;
}
section#modal[data-name=coverArt] > div.manual > div > input#url {
  display: block;
  margin-top: 5px;
}
section#modal[data-name=coverArt] > div.manual > div > input#upload {
  display: block;
  margin-top: 8px;
}
section#modal[data-name=coverArt] > div.manual > div > span.error {
  display: block;
  font-size: 80%;
  color: #ed4337;
  margin-top: 7px;
}
section#modal[data-name=coverArt] > div.manual > span {
  display: block;
  padding-top: 28px;
  font-style: italic;
  text-align: center;
  color: #aaa;
}

@keyframes loadingWave {
  0% {
    opacity: 0.3;
    height: 6px;
  }
  100% {
    opacity: 1;
    height: 100px;
  }
}
div.loading {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto 0;
}
div.loading > i {
  display: block;
  width: 7px;
  height: 100%;
  background: #f6ba2a;
  border-radius: 20px;
  animation: loadingWave 0ms -800ms linear infinite alternate;
}
div.loading > i:nth-child(1), div.loading > i:nth-child(11) {
  height: 2px;
  animation-duration: 474ms;
}
div.loading > i:nth-child(2), div.loading > i:nth-child(12) {
  height: 10px;
  animation-duration: 433ms;
}
div.loading > i:nth-child(3), div.loading > i:nth-child(13) {
  height: 18px;
  animation-duration: 407ms;
}
div.loading > i:nth-child(4), div.loading > i:nth-child(14) {
  height: 26px;
  animation-duration: 458ms;
}
div.loading > i:nth-child(5), div.loading > i:nth-child(15) {
  height: 30px;
  animation-duration: 400ms;
}
div.loading > i:nth-child(6), div.loading > i:nth-child(16) {
  height: 32px;
  animation-duration: 427ms;
}
div.loading > i:nth-child(7), div.loading > i:nth-child(17) {
  height: 34px;
  animation-duration: 441ms;
}
div.loading > i:nth-child(8), div.loading > i:nth-child(18) {
  height: 36px;
  animation-duration: 419ms;
}
div.loading > i:nth-child(9), div.loading > i:nth-child(19) {
  height: 40px;
  animation-duration: 487ms;
}
div.loading > i:nth-child(10), div.loading > i:nth-child(20) {
  height: 46px;
  animation-duration: 442ms;
}

p.loadingDesc {
  margin: 50px auto 0;
  font-size: 110%;
  color: #aaa;
}

div.progress {
  background: #eeeeee;
  width: 250px;
  height: 7px;
  margin: 50px auto 0;
  border-radius: 20px;
}
div.progress > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #f6ba2a;
  border-radius: 20px;
  transition: width 0.5s;
}
div.progress > span {
  display: block;
  padding-top: 18px;
  color: #aaa;
  font-size: 110%;
}

div.contextmenu {
  position: fixed;
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
  margin: 10px 0 0 -8px;
  text-align: left;
  z-index: 9997;
}
div.contextmenu > menu {
  list-style: none;
}
div.contextmenu > menu > li > button {
  display: block;
  width: 100%;
  padding: 10px 10px 10px 34px;
  font-size: 90%;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
}
div.contextmenu > menu > li > button.checkAll::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/22px;
  -webkit-mask-image: url(../img/icon-checkAll.svg);
  background-color: #aaa;
}
div.contextmenu > menu > li > button.checkUntil::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/20px;
  -webkit-mask-image: url(../img/icon-checkUntil.svg);
  background-color: #aaa;
}
div.contextmenu > menu > li > button.uncheckAll::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/20px;
  -webkit-mask-image: url(../img/icon-uncheckAll.svg);
  background-color: #aaa;
}
div.contextmenu > menu > li > button::before {
  width: 32px !important;
  transition: background-color 0.3s;
}
div.contextmenu > menu > li > button:hover {
  background: rgba(0, 0, 0, 0.04);
}
div.contextmenu > menu > li > button:hover::before {
  background: #666;
}

div.error > p {
  margin-bottom: 20px;
}

button.download {
  white-space: nowrap;
}
button.download > span {
  display: inline-block;
  vertical-align: top;
  margin: 1px 0 0 7px;
  font-size: 80%;
  color: rgba(255, 255, 255, 0.9);
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
section#playlist > form > ul > li > div > div.details > input, section#playlist > header div.prefix > div > input, section#details > div.videoDetails > form > div > input {
  border: 2px solid #aaa;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

section#finished > button, button.download {
  display: block;
  padding: 10px 20px;
  font-size: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px auto 0;
  background: #f6ba2a;
  color: #fff;
  transition: background-color 0.5s;
}

section#finished > button.disabled, button.disabled.download {
  background: #aaa;
  pointer-events: none;
}

@media (max-width: 900px) {
  section#finished > button, button.download {
    margin: 20px 0 0 0;
  }
}
section#playlist > form > ul > li > input[type=checkbox] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  border-radius: 3px;
  transition: border 0.2s, height 0.2s, transform 0.2s;
}

section#playlist > form > ul > li > input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]:checked::before {
  transform: rotateZ(-45deg);
  border-color: #7cce41;
  border-top-style: none;
  border-right-style: none;
  border-radius: 0;
  height: 50%;
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
section#search > p {
  font-size: 120%;
  margin-bottom: 20px;
}
section#search > form {
  display: grid;
  grid-template-columns: 1fr 40px;
  border: 3px solid #aaa;
  width: 100%;
  max-width: 400px;
  margin: auto;
}
section#search > form > * {
  border: none;
  outline: none;
  background: transparent;
}
section#search > form > input {
  padding: 10px 8px;
  font-weight: 400;
}
section#search > form > button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s;
}
section#search > form > button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/30px;
  -webkit-mask-image: url(../img/icon-arrow-r.svg);
  background-color: #f6ba2a;
}
section#search > form > button:hover {
  transform: translate3d(4px, 0, 0);
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
section#playlist > form > ul > li > div > div.details > input, section#playlist > header div.prefix > div > input, section#details > div.videoDetails > form > div > input {
  border: 2px solid #aaa;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

section#finished > button, button.download {
  display: block;
  padding: 10px 20px;
  font-size: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px auto 0;
  background: #f6ba2a;
  color: #fff;
  transition: background-color 0.5s;
}

section#finished > button.disabled, button.disabled.download {
  background: #aaa;
  pointer-events: none;
}

@media (max-width: 900px) {
  section#finished > button, button.download {
    margin: 20px 0 0 0;
  }
}
section#playlist > form > ul > li > input[type=checkbox] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  border-radius: 3px;
  transition: border 0.2s, height 0.2s, transform 0.2s;
}

section#playlist > form > ul > li > input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]:checked::before {
  transform: rotateZ(-45deg);
  border-color: #7cce41;
  border-top-style: none;
  border-right-style: none;
  border-radius: 0;
  height: 50%;
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
section#details {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 700px;
}
@media (max-width: 900px) {
  section#details {
    display: block;
  }
}
section#details > div.videoDetails {
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 12px;
}
@media (max-width: 900px) {
  section#details > div.videoDetails {
    display: block;
  }
}
section#details > div.videoDetails > strong {
  display: block;
  grid-column: 1/span 2;
  font-size: 120%;
  line-height: 140%;
}
@media (max-width: 900px) {
  section#details > div.videoDetails > strong {
    margin-bottom: 10px;
  }
}
section#details > div.videoDetails > div.cover {
  margin-bottom: 30px;
}
section#details > div.videoDetails > div.cover > span {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 260px;
  height: 260px;
  user-select: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
section#details > div.videoDetails > div.cover > span:hover {
  z-index: 3;
}
section#details > div.videoDetails > div.cover > span:active {
  transform: scale(2.5);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
}
section#details > div.videoDetails > div.cover > span > img {
  height: 100%;
  pointer-events: none;
}
section#details > div.videoDetails > div.cover > span > img[src=""] {
  width: 100%;
  font-size: 0;
}
section#details > div.videoDetails > div.cover > span > img[src=""]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eeeeee;
}
section#details > div.videoDetails > div.cover > button {
  cursor: pointer;
  margin-top: 3px;
  padding-left: 20px;
  font-size: 90%;
  border: none;
  outline: none;
  background: none;
  transition: opacity 0.3s;
}
section#details > div.videoDetails > div.cover > button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/16px;
  -webkit-mask-image: url(../img/icon-edit.svg);
  background-color: #aaa;
}
section#details > div.videoDetails > div.cover > button::before {
  width: 14px;
  height: 14px;
  top: 1px;
}
section#details > div.videoDetails > div.cover > div.loading {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  transform: scale(0.5);
  z-index: 2;
}
section#details > div.videoDetails > div.cover > div.loading + span {
  pointer-events: none;
}
section#details > div.videoDetails > form > div + div {
  margin-top: 20px;
}
section#details > div.videoDetails > form > div > label {
  font-weight: 400;
}
section#details > div.videoDetails > form > div > input {
  display: block;
  margin-top: 5px;
  max-width: 300px;
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
section#details > div.videoDetails > form > div > input, section#playlist > header div.prefix > div > input, section#playlist > form > ul > li > div > div.details > input {
  border: 2px solid #aaa;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

section#finished > button, button.download {
  display: block;
  padding: 10px 20px;
  font-size: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px auto 0;
  background: #f6ba2a;
  color: #fff;
  transition: background-color 0.5s;
}

section#finished > button.disabled, button.disabled.download {
  background: #aaa;
  pointer-events: none;
}

@media (max-width: 900px) {
  section#finished > button, button.download {
    margin: 20px 0 0 0;
  }
}
section#playlist > form > ul > li > input[type=checkbox] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  border-radius: 3px;
  transition: border 0.2s, height 0.2s, transform 0.2s;
}

section#playlist > form > ul > li > input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]:checked::before {
  transform: rotateZ(-45deg);
  border-color: #7cce41;
  border-top-style: none;
  border-right-style: none;
  border-radius: 0;
  height: 50%;
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
section#playlist {
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 20px;
  text-align: left;
}
@media (max-width: 900px) {
  section#playlist {
    display: block;
  }
}
section#playlist > header {
  position: sticky;
  top: 15px;
}
section#playlist > header > strong {
  display: block;
  padding-left: 32px;
  font-size: 120%;
  line-height: 140%;
}
section#playlist > header > strong::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/32px;
  -webkit-mask-image: url(../img/icon-playlist.svg);
  background-color: #aaa;
}
section#playlist > header > strong::before {
  top: -1px;
  left: -5px;
  width: 32px;
  height: 32px;
}
section#playlist > header > img {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 7px 0 15px;
}
section#playlist > header div.prefix {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  max-width: 125px;
  margin: 20px auto 0;
}
section#playlist > header div.prefix > div > label {
  font-size: 90%;
}
section#playlist > header div.prefix > div > input {
  font-size: 80%;
  padding: 2px 4px;
  margin: 2px 0 0 0;
}
@media (max-width: 900px) {
  section#playlist > header {
    position: relative;
    top: unset;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-bottom: 50px;
  }
  section#playlist > header > strong {
    grid-column: 1/span 2;
  }
  section#playlist > header > img {
    margin: 0;
  }
  section#playlist > header > div {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
  }
  section#playlist > header > div > button.back {
    display: none;
  }
  section#playlist > header > div > button.download {
    margin: 0 20px 0 0;
  }
  section#playlist > header > div > div.prefix {
    margin: 0;
  }
}
@media (max-width: 500px) {
  section#playlist > header {
    display: block;
  }
  section#playlist > header > img {
    margin: 10px 0 22px;
  }
}
section#playlist > form {
  grid-column: 2;
  grid-row: 1/span 2;
  margin-top: 5px;
}
section#playlist > form > div.loading {
  transform: scale(0.5);
  margin-top: 30px;
}
section#playlist > form > ul > li {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-column-gap: 5px;
}
section#playlist > form > ul > li + li {
  margin-top: 40px;
}
section#playlist > form > ul > li + li::before {
  content: "";
  position: absolute;
  top: -19px;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
}
section#playlist > form > ul > li > input[type=checkbox] {
  margin-top: 2px;
}
section#playlist > form > ul > li > div {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
}
section#playlist > form > ul > li > div > strong {
  grid-column: 1/span 2;
  font-size: 100%;
}
section#playlist > form > ul > li > div > div.cover > span {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 120px;
  height: 120px;
  user-select: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
section#playlist > form > ul > li > div > div.cover > span:hover {
  z-index: 3;
}
section#playlist > form > ul > li > div > div.cover > span:active {
  transform: scale(5);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
}
section#playlist > form > ul > li > div > div.cover > span > img {
  height: 100%;
  pointer-events: none;
}
section#playlist > form > ul > li > div > div.cover > span > img[src=""] {
  width: 100%;
  font-size: 0;
}
section#playlist > form > ul > li > div > div.cover > span > img[src=""]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eeeeee;
}
section#playlist > form > ul > li > div > div.cover > button {
  cursor: pointer;
  margin-top: 3px;
  padding-left: 16px;
  font-size: 80%;
  border: none;
  outline: none;
  background: none;
  transition: opacity 0.3s;
}
section#playlist > form > ul > li > div > div.cover > button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/12px;
  -webkit-mask-image: url(../img/icon-edit.svg);
  background-color: #aaa;
}
section#playlist > form > ul > li > div > div.cover > button::before {
  width: 12px;
  height: 12px;
  top: 1px;
}
section#playlist > form > ul > li > div > div.cover > div.loading {
  position: absolute;
  transform: scale(0.4) translateX(-100px);
  z-index: 2;
}
section#playlist > form > ul > li > div > div.cover > div.loading + span {
  pointer-events: none;
}
section#playlist > form > ul > li > div > div.details > input {
  display: block;
  font-size: 90%;
  padding: 6px 6px;
  max-width: 300px;
}
section#playlist > form > ul > li > div > div.details > input + input {
  margin-top: 12px;
}
@media (max-width: 500px) {
  section#playlist > form > ul > li > div {
    display: block;
  }
  section#playlist > form > ul > li > div > div.cover {
    margin: 5px 0 10px;
  }
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 200;
  src: local("Work Sans ExtraLight"), local("WorkSans-ExtraLight"), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Work Sans Light"), local("WorkSans-Light"), url(../font/Work_Sans/WorkSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), local("WorkSans-Regular"), url(../font/Work_Sans/WorkSans-Regular.woff2) format("woff2");
}
section#details > div.videoDetails > form > div > input, section#playlist > header div.prefix > div > input, section#playlist > form > ul > li > div > div.details > input {
  border: 2px solid #aaa;
  outline: none;
  background: transparent;
  padding: 5px 7px;
  width: 100%;
}

button.download, section#finished > button {
  display: block;
  padding: 10px 20px;
  font-size: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px auto 0;
  background: #f6ba2a;
  color: #fff;
  transition: background-color 0.5s;
}

button.disabled.download, section#finished > button.disabled {
  background: #aaa;
  pointer-events: none;
}

@media (max-width: 900px) {
  button.download, section#finished > button {
    margin: 20px 0 0 0;
  }
}
section#playlist > form > ul > li > input[type=checkbox] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  border-radius: 3px;
  transition: border 0.2s, height 0.2s, transform 0.2s;
}

section#playlist > form > ul > li > input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  cursor: pointer;
}

section#playlist > form > ul > li > input[type=checkbox]:checked::before {
  transform: rotateZ(-45deg);
  border-color: #7cce41;
  border-top-style: none;
  border-right-style: none;
  border-radius: 0;
  height: 50%;
}

@keyframes spin {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
section#finished > p {
  font-size: 120%;
  line-height: 140%;
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  section#finished > p {
    font-size: 110%;
    line-height: 130%;
    margin-bottom: 30px;
  }
}
section#finished > button {
  display: inline-block;
}

@keyframes broken {
  25% {
    transform: rotateZ(10deg);
  }
  75% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
section#notFound {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
section#notFound > p {
  height: 350px;
  font-size: 140%;
  line-height: 140%;
  padding-top: 100px;
  margin-bottom: 60px;
  color: #aaa;
}
section#notFound > p::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask: no-repeat center/250px;
  -webkit-mask-image: url(../img/icon-broken.svg);
  background-color: #eeeeee;
}
section#notFound > p::before {
  z-index: -1;
  transform-origin: center 0;
  animation: broken 6s linear infinite;
}
@media (max-width: 900px) {
  section#notFound > p {
    font-size: 130%;
  }
  section#notFound > p::before {
    -webkit-mask-size: 200px;
    mask-size: 200px;
  }
}
section#notFound > a {
  font-size: 110%;
}

