@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: local('Work Sans ExtraLight'), local('WorkSans-ExtraLight'), url(../font/Work_Sans/WorkSans-ExtraLight.woff2) format('woff2');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(../font/Work_Sans/WorkSans-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'), url(../font/Work_Sans/WorkSans-Regular.woff2) format('woff2');
}

$font-size: 16px;
$font-weight: 300;
$font-family: "Work Sans", sans-serif;

$color-fg-text: #666;
$color-fg-light: #aaa;
$color-fg-lighter: #ccc;
$color-fg-white: #fff;
$color-fg-darker: #333;
$color-fg-main: #f6ba2a;
$color-fg-green: #7cce41;
$color-fg-red: #ed4337;
$color-fg-yellow: #f6ba2a;

$color-bg-body: #ffffff;
$color-bg-darker: #eeeeee;
$color-bg-dark: #444444;

$layout-content-width: 800px;
$layout-content-padding: 0 20px;
