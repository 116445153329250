
section#modal[data-name='info'] {
  max-width: 400px;
  text-align: center;

  > p {
    font-size: 140%;
    line-height: 140%;

    @media (max-width: 500px) {
      font-size: 120%;
      line-height: 130%;
    }
  }
}