@use "../vars" as *;

div#app {
  display: grid;
  grid-template-rows: 200px 1fr 80px;
  min-height: 100vh;

  @media (max-width: 900px) {
    grid-template-rows: 150px 1fr 80px;
  }

  @media (max-width: 640px) {
    grid-template-rows: 150px 1fr 120px;
  }

  main {
    width: 100%;
    max-width: $layout-content-width;
    padding: $layout-content-padding;
    padding-top: 50px;
    margin: 0 auto 60px;
    text-align: center;

    @media (max-width: 900px) {
      padding-top: 35px;
      margin-bottom: 50px;
    }
  }
}