@use "../../vars" as *;
@use "../../funcs" as *;

section#details {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 700px;

  @media (max-width: 900px) {
    display: block;
  }

  > div.videoDetails {
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 12px;

    @media (max-width: 900px) {
      display: block;
    }

    > strong {
      display: block;
      grid-column: 1 / span 2;
      font-size: 120%;
      line-height: 140%;

      @media (max-width: 900px) {
        margin-bottom: 10px;
      }
    }

    > div.cover {
      margin-bottom: 30px;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 260px;
        height: 260px;
        user-select: none;
        transition: transform 0.3s, box-shadow 0.3s;

        &:hover {
          z-index: 3;
        }

        &:active {
          transform: scale(2.5);
          box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
        }

        > img {
          height: 100%;
          pointer-events: none;

          &[src=''] {
            width: 100%;
            font-size: 0;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: $color-bg-darker;
            }
          }
        }
      }

      > button {
        @include mask("icon-edit", $color-fg-light, 16px);
        cursor: pointer;
        margin-top: 3px;
        padding-left: 20px;
        font-size: 90%;
        border: none;
        outline: none;
        background: none;
        transition: opacity 0.3s;

        &::before {
          width: 14px;
          height: 14px;
          top: 1px;
        }
      }

      > div.loading {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        transform: scale(0.5);
        z-index: 2;

        + span {
          pointer-events: none;
        }
      }
    }

    > form {

      > div {

        + div {
          margin-top: 20px;
        }

        > label {
          font-weight: 400;
        }

        > input {
          @extend %formField;
          display: block;
          margin-top: 5px;
          max-width: 300px;
        }
      }
    }
  }
}
