@use "../vars" as *;
@use "../funcs" as *;

div.error {

  > p {
    margin-bottom: 20px;
  }
}

button.download {
  @extend %button;
  white-space: nowrap;

  > span {
    display: inline-block;
    vertical-align: top;
    margin: 1px 0 0 7px;
    font-size: 80%;
    color: rgba($color-fg-white, 0.9);
  }
}

//
//
@import "downloader/input";
@import "downloader/videoDetails";
@import "downloader/playlistDetails";
@import "downloader/finish";