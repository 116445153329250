@use "../vars" as *;
@use "../funcs" as *;

div#app > header {
  background: $color-bg-dark;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-left: 100vw solid $color-bg-body;
    z-index: 1;

    @media (max-width: 900px) {
      border-top-width: 50px;
    }
  }

  > div {
    margin: auto;
    height: 100%;
    padding: $layout-content-padding;
    max-width: $layout-content-width;
    z-index: 2;

    > a#logo {
      @include mask("logo", $color-fg-white, 50px);
      display: block;
      width: 120px;
      height: 100%;
      font-size: 0;
      color: transparent;
      background: $color-fg-main;
      margin-top: -30px;
      border-radius: 0 0 15px 15px;
      transform: rotate(10deg);

      &::before {
        transform: translateX(-4px) rotate(-10deg);
        pointer-events: none;
      }

      @media (max-width: 900px) {
        @include maskSize(40px);
        margin: $layout-content-padding;
        margin-top: -12px;
        width: 100px;
      }
    }
  }
}