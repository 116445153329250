@use "../vars" as *;
@use "../funcs" as *;

div.contextmenu {
  position: fixed;
  background: $color-bg-body;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
  margin: 10px 0 0 -8px;
  text-align: left;
  z-index: 9997;

  > menu {
    list-style: none;

    > li {

      > button {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 34px;
        font-size: 90%;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        text-align: left;
        transition: background-color 0.3s;

        &.checkAll {
          @include mask("icon-checkAll", $color-fg-light, 22px);
        }

        &.checkUntil {
          @include mask("icon-checkUntil", $color-fg-light, 20px);
        }

        &.uncheckAll {
          @include mask("icon-uncheckAll", $color-fg-light, 20px);
        }

        &::before {
          width: 32px !important;
          transition: background-color 0.3s;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.04);

          &::before {
            background: $color-fg-text;
          }
        }
      }
    }
  }
}