@use "../../funcs" as *;

section#finished {

  > p {
    font-size: 120%;
    line-height: 140%;
    margin-bottom: 40px;

    @media (max-width: 500px) {
      font-size: 110%;
      line-height: 130%;
      margin-bottom: 30px;
    }
  }

  > button {
    @extend %button;
    display: inline-block;
  }
}