@use "../../vars" as *;
@use "../../funcs" as *;

section#search {

  > p {
    font-size: 120%;
    margin-bottom: 20px;
  }

  > form {
    display: grid;
    grid-template-columns: 1fr 40px;
    border: 3px solid $color-fg-light;
    width: 100%;
    max-width: 400px;
    margin: auto;

    > * {
      border: none;
      outline: none;
      background: transparent;
    }

    > input {
      padding: 10px 8px;
      font-weight: 400;
    }

    > button {
      @include mask("icon-arrow-r", $color-fg-main, 30px);
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: transform 0.2s;

      &:hover {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

}