@use "../../vars" as *;

body {

  &::before {
    content: "";
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-bg-body, 0.2);
    backdrop-filter: blur(20px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  &.modal-visible::before {
    opacity: 1;
    pointer-events: auto;
  }
}


section#modal {
  position: fixed;
  z-index: 999;
  width: calc(100% - 30px);
  top: 50px;
  left: 15px;
  right: 15px;
  text-align: left;
  margin: auto;
  background: $color-bg-body;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.3);
  padding: 30px 20px 20px;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, -20px, 0);
  transition: opacity 0.3s, transform 0.3s;

  &.visible {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }

  > button.dismiss {
    border: none;
    outline: none;
    background: none;
    color: $color-fg-main;
    margin-top: 20px;
    padding: 2px 7px;
    font-size: 110%;
    cursor: pointer;
    font-weight: 400;
    transition: background-color 0.3s;

    &:hover {
      background: rgba($color-fg-main, 0.1);
    }

    @media (max-width: 500px) {
      font-size: 100%;
    }
  }
}