@use "../../vars" as *;
@use "../../funcs" as *;

section#modal[data-name='coverArt'] {
  max-width: 800px;

  > header {
    height: 85px;
    padding-left: 115px;

    > span.cover {
      position: absolute;
      top: -55px;
      left: -45px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 130px;
      height: 130px;
      background: $color-bg-darker;
      user-select: none;
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s, box-shadow 0.3s;

      &:hover {
        z-index: 3;
      }

      &:active {
        transform: scale(3) translate(42px, 42px);
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
      }

      > img {
        height: 100%;
        pointer-events: none;

        &[src=''] {
          width: 100%;
          font-size: 0;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-bg-darker;
          }
        }
      }
    }

    > strong {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 120%;
    }

    > menu {
      display: flex;
      margin-top: 15px;

      > li {
        padding: 5px 12px;
        color: $color-fg-light;
        cursor: pointer;

        &.active {
          color: $color-fg-white;
          background: $color-fg-main;
        }

        &.search {
          @include mask("icon-search", $color-fg-light, 16px);
          margin-left: auto;
          padding: 0 3px 0 32px;
          width: 200px;
          border: 2px solid $color-fg-light;

          &::before {
            width: 32px;
          }

          > input {
            border: none;
            outline: none;
            background: transparent;
            width: 100%;
            padding: 3px 0;
          }
        }
      }
    }
  }

  > ul.suggestions {
    overflow-x: auto;
    max-height: calc(100vh - 280px);
    margin-right: -20px;

    > li {
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-gap: 20px;
      padding: 12px 10px;
      transition: background-color 0.3s;

      &:hover {
        background: rgba(0, 0, 0, 0.05);

        > span.cover {
          z-index: 3;
        }
      }

      + li {

        &::before {
          background: rgba(0, 0, 0, 0.2);
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      > span.cover {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 100px;
        height: 100px;
        user-select: none;
        top: 0;
        left: 0;
        transition: transform 0.3s, top 0.3s, left 0.3s, box-shadow 0.3s;

        &:active {
          transform: scale(3.5);
          left: 125px;
          box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
        }

        > img {
          height: 100%;
          pointer-events: none;
        }
      }

      &:first-child > span.cover:active {
        top: 125px;
      }

      &:last-child > span.cover:active {
        top: -125px;
      }

      &[data-source="thumbnail"] > div.info {
        @include mask("icon-youtube", $color-fg-light, 22px);
      }

      &[data-source="spotify"] > div.info {
        @include mask("icon-spotify", $color-fg-light, 22px);
      }

      &[data-source="soundcloud"] > div.info {
        @include mask("icon-soundcloud", $color-fg-light, 22px);
      }

      > div.info {
        padding-top: 10px;

        &::before {
          bottom: 7px;
          top: unset !important;
          width: 24px !important;
          height: 24px !important;
        }

        > span.artist {
          display: block;
          font-size: 90%;
          margin-bottom: 5px;
          color: $color-fg-light;
        }

        > span.track {
          display: block;
        }
      }
    }
  }

  > div {
    min-height: 200px;

    &.loading {
      transform: scale(0.5);
      margin: 0;
    }

    &.suggestion-empty {
      padding: 30px 10px;
      font-style: italic;
      font-size: 110%;
    }

    &.manual {
      display: grid;
      grid-template-columns: 1fr 50px 1fr;
      grid-gap: 20px;
      padding: 30px 10px;

      > div {
        > label {
          font-weight: 400;
        }

        > input#url {
          @extend %formField;
          display: block;
          margin-top: 5px;
        }

        > input#upload {
          display: block;
          margin-top: 8px;
        }

        > span.error {
          display: block;
          font-size: 80%;
          color: $color-fg-red;
          margin-top: 7px;
        }
      }

      > span {
        display: block;
        padding-top: 28px;
        font-style: italic;
        text-align: center;
        color: $color-fg-light;
      }
    }
  }
}