@use "vars" as *;

body, input, textarea, select, button {
  font-size: $font-size;
  font-family: $font-family;
  color: $color-fg-text;
  font-weight: $font-weight;
}

*, *::before, *::after {
  position: relative;
  margin: 0;
  padding: 0;
  outline: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  background: $color-bg-body;
  overflow-x: hidden;
}

// List
ul, menu {
  list-style: none;

  &.bulletList {

    > li {
      padding-left: 14px;

      + li {
        margin-top: 3px;
      }

      &::before {
        content: "\2013";
        margin: 0 6px 0 -16px;
        display: inline-block;
      }
    }
  }
}

// Inline
a {
  text-decoration: none;
  color: $color-fg-text;
  cursor: pointer;
}

a.back, button.back {
  cursor: pointer;
  padding-left: 15px;
  border: none;
  outline: none;
  background: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 2px solid $color-fg-light;
    border-left: 2px solid $color-fg-light;
    transform: rotateZ(-45deg);
  }

  @media (max-width: 900px) {
    margin: 20px 0 0 0;
  }
}

strong {
  font-weight: 400;
}

picture {
  width: 100%;
}

picture > img {
  width: 100%;
}