@use "../vars" as *;

@keyframes loadingWave {
  0% {
    opacity: 0.3;
    height: 6px;
  }
  100% {
    opacity: 1;
    height: 100px;
  }
}

div.loading {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto 0;

  > i {
    display: block;
    width: 7px;
    height: 100%;
    background: $color-fg-main;
    border-radius: 20px;
    animation: loadingWave 0ms -800ms linear infinite alternate;

    &:nth-child(1), &:nth-child(11) {
      height: 2px;
      animation-duration: 474ms;
    }

    &:nth-child(2), &:nth-child(12) {
      height: 10px;
      animation-duration: 433ms;
    }

    &:nth-child(3), &:nth-child(13) {
      height: 18px;
      animation-duration: 407ms;
    }

    &:nth-child(4), &:nth-child(14) {
      height: 26px;
      animation-duration: 458ms;
    }

    &:nth-child(5), &:nth-child(15) {
      height: 30px;
      animation-duration: 400ms;
    }

    &:nth-child(6), &:nth-child(16) {
      height: 32px;
      animation-duration: 427ms;
    }

    &:nth-child(7), &:nth-child(17) {
      height: 34px;
      animation-duration: 441ms;
    }

    &:nth-child(8), &:nth-child(18) {
      height: 36px;
      animation-duration: 419ms;
    }

    &:nth-child(9), &:nth-child(19) {
      height: 40px;
      animation-duration: 487ms;
    }

    &:nth-child(10), &:nth-child(20) {
      height: 46px;
      animation-duration: 442ms;
    }
  }
}

p.loadingDesc {
  margin: 50px auto 0;
  font-size: 110%;
  color: $color-fg-light;
}

div.progress {
  background: $color-bg-darker;
  width: 250px;
  height: 7px;
  margin: 50px auto 0;
  border-radius: 20px;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $color-fg-main;
    border-radius: 20px;
    transition: width 0.5s;
  }

  > span {
    display: block;
    padding-top: 18px;
    color: $color-fg-light;
    font-size: 110%;
  }
}

