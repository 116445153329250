@use "../vars" as *;
@use "../funcs" as *;

@keyframes broken {
  25% {
    transform: rotateZ(10deg);
  }

  75% {
    transform: rotateZ(-10deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}

section#notFound {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  > p {
    @include mask("icon-broken", $color-bg-darker, 250px);
    height: 350px;
    font-size: 140%;
    line-height: 140%;
    padding-top: 100px;
    margin-bottom: 60px;
    color: $color-fg-light;

    &::before {
      z-index: -1;
      transform-origin: center 0;
      animation: broken 6s linear infinite;
    }

    @media (max-width: 900px) {
      @include maskSize(200px);
      font-size: 130%;
    }
  }

  > a {
    font-size: 110%;
  }
}